// TODO: Fix eslint errors.
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from 'react-intl';
import HeaderText from '@UI/HeaderText';
import BlogFilter from '@Filters/Blog';
import SortBar from '@Components/SortBar';
import Pager from '@Components/Pager';
import Breadcrumbs from '@Components/Breadcrumbs';
import NoResults from '@Components/NoResults';
import Block from '@UI/Block';
import Optimizely from '@TrackingComponents/Optimizely';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import generateDataLayer from 'dataLayerHelper';
import searchParamsStringify from '@Utils/searchParamsStringify';
import GetRouteFromUrl from 'GetRouteFromUrl';
import ArticleList from '@UI/ArticleOverview/List';
import { getCurrentCategoryName } from 'facetsHelper';
import NotFound from '@Pages/NotFound';
import withSearch from '../../components/hoc/Search';

function Franchise({
  intl, searchParams, pager, articles, total, canonicalOverrides, staticContext,
}) {
  const { locale } = intl;
  const route = useRouteMatch();
  const routeConfig = GetRouteFromUrl();
  const baseUrl = route.path.replace(':searchParams*', '');
  if (searchParams && searchParams.page && parseInt(searchParams.page) === 1) {
    return (
      <Route render={({ staticContext }) => {
        if (staticContext) staticContext.status = 301;
        return <Redirect to={`${baseUrl}${searchParamsStringify('franchise', { ...searchParams, ...{ page: null } })}`} />;
      }}
      />
    );
  }
  if (searchParams && searchParams.unknown) {
    return (
      <Route render={({ staticContext }) => {
        if (staticContext) staticContext.status = 404;
        return <NotFound staticContext={staticContext} />;
      }}
      />
    );
  }
  if (staticContext && staticContext.data) {
    // Pass the dataLayer to the server using staticContext.
    if (staticContext.appConfigurations
      && Object.prototype.hasOwnProperty.call(staticContext.appConfigurations, 'rbd_tracking.settings')
      && staticContext.appConfigurations['rbd_tracking.settings'].gtm
      && staticContext.appConfigurations['rbd_tracking.settings'].gtm.enabled) {
      staticContext.dataLayer = generateDataLayer('other_content', locale);
    }
  }
  let metaTitle = intl.formatMessage({ id: 'Franchise.Title' }, { sitename: process.env.REACT_APP_SITENAME });
  let metaDescription = intl.messages['Franchise.MetaDescription'] ? intl.messages['Franchise.MetaDescription'] : null;
  let titleTop = intl.formatMessage({ id: 'FranchiseHeader.TitleTop' });
  let titleBottom = intl.messages['FranchiseHeader.TitleBottom'] ? intl.formatMessage({ id: 'FranchiseHeader.TitleBottom' }) : null;

  const categoryName = searchParams.facets ? getCurrentCategoryName(searchParams.facets) : '';
  if (categoryName.length) {
    metaTitle = intl.messages['Franchise.CategoryTitle'] ? intl.formatMessage({ id: 'Franchise.CategoryTitle' }, {
      sitename: process.env.REACT_APP_SITENAME,
      category: categoryName,
    }) : metaTitle;
    metaDescription = intl.messages['Franchise.CategoryMetaDescriptionTitle'] ? intl.formatMessage({ id: 'Franchise.CategoryMetaDescriptionTitle' }, {
      sitename: process.env.REACT_APP_SITENAME,
      category: categoryName,
    }) : metaDescription;
    titleTop = intl.messages['FranchiseHeader.CategoryTitleTop'] ? intl.formatMessage({ id: 'FranchiseHeader.CategoryTitleTop' }, { category: categoryName }) : titleTop;
    titleBottom = intl.messages['FranchiseHeader.CategoryTitleTopBottom'] ? intl.formatMessage({ id: 'FranchiseHeader.CategoryTitleTopBottom' }) : titleBottom;
  }
  metaTitle = metaTitle.charAt(0).toUpperCase() + metaTitle.slice(1);
  if (searchParams.page && intl.messages['Global.Page']) {
    metaTitle = `${intl.formatMessage({ id: 'Global.Page' }, { page: searchParams.page })} ${metaTitle}`;
  }

  const searchParamsString = searchParamsStringify('franchise', searchParams);
  let canonicalLink = baseUrl + searchParamsString;
  // Page's canonical link, can be overridden,
  // from CMS configuration.
  if (canonicalOverrides && Object.keys(canonicalOverrides).length > 0) {
    let overrideValue;
    if (canonicalOverrides[canonicalLink]) {
      // First check for full match.
      overrideValue = canonicalOverrides[canonicalLink] ? canonicalOverrides[canonicalLink] : '';
    } else if (searchParams && searchParams.page) {
      const { page } = searchParams;
      searchParams.page = null;
      const noPager = baseUrl + searchParamsStringify('franchise', searchParams);
      searchParams.page = page;
      if (canonicalOverrides[noPager]) {
        const baseOverrideValue = canonicalOverrides[noPager];
        overrideValue = canonicalLink.replace(noPager, baseOverrideValue);
      }
    }
    if (canonicalOverrides[baseUrl] && overrideValue === undefined) {
      // Try to apply wildcard, for base path override.
      overrideValue = canonicalOverrides[baseUrl] + searchParamsString;
    }
    if (overrideValue && overrideValue.length > 1) canonicalLink = overrideValue;
  }

  return (
    <div id="articles-search-listing">
      <Optimizely />
      <Helmet>
        <title>{metaTitle}</title>
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}${canonicalLink}`} />
      </Helmet>
      <Breadcrumbs breadcrumbs={routeConfig.breadcrumbs} currentRoute={route} searchParams={searchParams} pageType="franchise" />
      <HeaderText
        bgColor={process.env.REACT_APP_HEADER_VARIATION || 'blue'}
        titleTop={titleTop}
        titleBottom={titleBottom}
      >
        {intl.messages['FranchiseHeader.text'] && intl.formatMessage({ id: 'FranchiseHeader.text' })}
      </HeaderText>
      <SortBar resultsTotal={total} searchParams={searchParams} />
      <Block beforeContent={<BlogFilter pageType="franchise" searchParams={searchParams} resultsTotal={total} />} align="right" contentSize="s" type="filter">
        <ArticleList>
          {total > 0 ? articles : <NoResults />}
        </ArticleList>
        <Pager previous={pager.previous} pagesList={pager.pages} next={pager.next} />
      </Block>
    </div>
  );
}

export default injectIntl(withSearch(Franchise));
